import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Box, Button, Image, Text, Flex } from 'rebass';
import { Link } from '../components/Link';

import SEO from '../components/seo';
import { Layout } from '../layout';
import { Container } from '../components/container';
import { Header } from '../components/Header';
import Form from '../components/Form';

import EnvelopeIcon from '../icons/envelope.svg';

const Input = styled.input`
  width: 100%;
  max-width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.lightBlueGrey};
  background-color: #ffffff;
  border-radius: 4px 0 0 4px;
`;

const RequestInvitationPage = props => {
  const { t, i18n } = useTranslation();

  return (
    <Layout {...props}>
      <SEO title="REQUEST INVITATION" keywords={['weboxit', 'boxit']} />
      <Header
        title={t('request_invitation.title')}
        subtitle={t('request_invitation.subtitle')}
      />
      <Container>
        <Box pt="70px" width="74px" mx="auto">
          <Image mx="auto" src={EnvelopeIcon} />{' '}
        </Box>
        <Text pt="56px" fontSize="20px" textAlign="center" color="marine">
          {t('request_invitation.please_enter_your_email')}
          <br />
          {t('request_invitation.thank_you')}
        </Text>
        <Box mt={[4, 4, '54px']} width={[1, 0.55, 0.45]} mx="auto">
          <form
            id="request-invitation-form"
            name="requestInvitationForm"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action={`${
              i18n.language === 'el' ? '' : '/en'
            }/request_invitation_confirmation`}
          >
            <input
              type="hidden"
              name="form-name"
              value="requestInvitationForm"
            />
            <Flex
              width={[1, '600px']}
              flexWrap="wrap"
              mx="auto"
              flexDirection="row"
            >
              <Box width={[1, 1 / 2]} paddingBottom={['20px', 0]}>
                <Input
                  name="Email"
                  type="email"
                  required={true}
                  placeholder={t('request_invitation.placeholder')}
                  width={[1, 1 / 2]}
                />
              </Box>
              <Button
                px="29px"
                py="8px"
                bg="blues.peacock"
                width={[1, 1 / 2]}
                mb="9px"
                css={{ borderRadius: '0 4px 4px 0' }}
              >
                {t('request_invitation.button')}
              </Button>

              <Flex pt="30px">
                <input name="Terms of use" type="checkbox" required={true} />
                <Text
                  mt="-3px"
                  ml={['10px', '20px']}
                  pr={['10px', '20px']}
                  color="marine"
                  fontSize={['12px', '13px', '15px']}
                  lineHeight={['20px', '28px']}
                  letterSpacing={['0.5px', '0.7px']}
                >
                  {t('support.contact_us.form.by_signing_up')}
                  <br />
                  {t('support.contact_us.form.visit_our_legal.begin')}
                  <Link to="/legal-info">
                    {t('support.contact_us.form.visit_our_legal.link')}
                  </Link>
                  {t('support.contact_us.form.visit_our_legal.end')}
                </Text>
              </Flex>
            </Flex>
            <input type="hidden" name="bot-field" />
          </form>
        </Box>
      </Container>
    </Layout>
  );
};

export default RequestInvitationPage;
